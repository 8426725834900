<template>
  <div id="mian">
    <div class="header">
      <span @click="toUrl('subaccountlist_xq')">基本信息</span>
      <span @click="toUrl('add_subaccountlist_shop')">分账商户信息</span>
      <span id="fzcolor">电子账簿信息</span>
    </div>
    <div class="desc">
      <h5 class="jsxx">电子账簿渠道信息</h5>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td style="padding-left: 10px">
            <p>渠道名称</p>
          </td>
          <td>
            <p>机构名称</p>
          </td>
          <td>
            <p>商户号</p>
          </td>
          <td>
            <p>产品信息</p>
          </td>
          <td>
            <p>操作</p>
          </td>
        </tr>
        <tr v-if="AccBooChannelShow == true">
          <td style="padding-left: 10px">
            <p>{{ detail.allocMchName }}</p>
          </td>
          <td>
            <p>{{ AccBooChannel.orgName}}</p>
          </td>
          <td>
            <p>{{ AccBooChannel.mchChannelId }}</p>
          </td>
          <td>
            <p><i
                class="lianjie"
                @click="configTab(AccBooChannel,1)"
              >配置</i></p>
          </td>
          <td>
            <p>
              <i
                class="lianjie"
                @click="editAddAccountBook(AccBooChannel)"
              >编辑</i>
              <i
                class="lianjie"
                @click="delAddAccountBook(AccBooChannel.mchChannelId)"
              >删除</i>
            </p>
          </td>
        </tr>
      </table>
    </div>
    <h6
      v-if="AccBooChannelShow != true"
      class="xzjsxx"
      @click="addAccountBook"
    >
      <span>+</span> 电子账簿渠道信息
    </h6>

    <el-dialog title="电子账簿渠道信息" :visible.sync="addAccountBookShow" width="490px">
        <ul class="overAccount_ul">
          <li class="ul_left" style="margin: 0px 24px;display: flex;flex-wrap: wrap;justify-content: space-between;width: 440px;">
            <div class="item">
              <div class="overAccount_ul_title">渠道名称</div>
              <el-input
                v-model="detail.mchName"
                maxlength="128"
                placeholder="同商户名称"
                class="width100"
                disabled
                tabindex="1"
              ></el-input>
            </div>
            <div class="item">
              <div class="overAccount_ul_title"><i>*</i>所属机构</div>
              <el-select
                v-model="accountBook.orgId"
                placeholder="选择所属机构"
                class="width100"
                tabindex="2"
              >
              <el-option
                  v-for="(v, i) in organizationsLists"
                  :key="i"
                  :label="v.orgName"
                  :value="v.orgId"
                >
                </el-option>
              </el-select>
            </div>
            <div class="item">
              <div class="overAccount_ul_title"><i>*</i>渠道类型</div>
              <el-input
                maxlength="128"
                :placeholder="accountBook.mchModel==0 ? '单商户模式' : '服务商模式'"
                class="width100"
                disabled
                tabindex="2"
              ></el-input>
            </div>
            <div class="item">
              <div class="overAccount_ul_title"><i>*</i>商户号</div>
              <el-input
                v-model="accountBook.mchChannelId"
                maxlength="128"
                placeholder="输入商户号"
                class="width100"
                tabindex="5"
              ></el-input>
            </div>
            <div class="item" v-if="accountBook.mchModel==0">
              <div class="overAccount_ul_title"><i>*</i>商户RSA私钥</div>
              <el-input
                v-model="accountBook.mchPrivateKey"
                maxlength="2048"
                placeholder="输入商户RSA私钥"
                class="width100"
                tabindex="7"
              ></el-input>
            </div>
            <div class="item" v-if="accountBook.mchModel==0">
              <div class="overAccount_ul_title"><i>*</i>商户RSA公钥</div>
              <el-input
                v-model="accountBook.mchPublicKey"
                maxlength="2048"
                placeholder="输入商户RSA公钥"
                class="width100"
                tabindex="7"
              ></el-input> 
            </div> 
            <div class="item" v-if="accountBook.mchModel==0">
              <div class="overAccount_ul_title"><i>*</i>机构RSA公钥</div>
              <el-input
                v-model="accountBook.orgPublicKey"
                maxlength="2048"
                placeholder="输入机构RSA公钥"
                class="width100"
                tabindex="9"
              ></el-input>
            </div>
            <div class="item">
              <div class="overAccount_ul_title">入金回调地址</div>
              <el-input
                v-model="accountBook.notifyUrl"
                maxlength="2048"
                placeholder="输入入金回调地址"
                class="width100"
                tabindex="6"
              ></el-input>
            </div>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="exitBt" @click="addAccountBookShow = false">取 消</el-button>
          <el-button class="addBt" :disabled="buttonFlag" @click="sureAddAccountBook"
            >确 定</el-button
          >
        </span>
      </el-dialog> 

  </div>
</template>
<script>
import { organizationsDrop } from "@/api/common.js";
import { getAllocmerchantsDetail } from "@/api/user/subaccount.js";
import { getAccountBookChannelDetail, postAccountBookChannelAdd, delAccountBookChannel } from "@/api/trading/electron.js";
export default {
  data() {
    return {
      organizationsLists: [], //支付机构列表
      buttonFlag: false, //防止重复提交
      detail:{},
      AccBooChannel: {},
      AccBooChannelShow: false,
      accountBook: {
        accountName: "",
        accountNo: "",
        aesKey: "",
        id: "",//商户Id
        mchChannelId: "",//商户号
        mchChannelName: "",//商户名称
        mchKey: "",
        mchPrivateKey: "",//商户RSA私钥
        mchPublicKey: "",//商户RSA公钥
        notifyUrl: "",//入金回调地址
        orgId: "",//所属机构
        orgPublicKey: "",//机构RSA公钥
        receiveAccountBankName: "",
        receiveAccountName: "",
        receiveAccountNo: "",
        allocMchId:"",
        allocMchName:"",
        mchId:"",
        mchName:"",
        mchModel:"",// 0就是单商户，1是服务商
      },
      addAccountBookShow: false,
      oldBookKey: "",

    };
  },
  created() {
    this.getOrganizationsLists();
    this.getDetail();
    this.getAccountBookDetail(this.$route.query.id);
    console.log(this.$route.query.id,"this.$route.query.id")
  },
  methods: {
    //获取详情
    getDetail() {
      getAllocmerchantsDetail(this.$route.query.id).then((res) => {
        if (res.resultStatus) {
          this.detail = res.resultData.mchDetail;
        }
      });
    },
    // 获取支付机构列表
    getOrganizationsLists() {
      organizationsDrop().then((res) => {
        this.organizationsLists = res.resultData;
        console.log(this.organizationsLists, 222222)
      });
    },
    //获取电子账簿渠道信息
    getAccountBookDetail(mchId) {
      getAccountBookChannelDetail(mchId).then((res) => {
        if (res) {
          if (res.resultData.mchChannel != null) {
            this.AccBooChannel = res.resultData.mchChannel;
            this.AccBooChannelShow = true
          } else {
            this.AccBooChannelShow = false
          }
        }
      })
    },
    //添加电子账簿渠道信息
    addAccountBook() {
      this.accountBook = {
        accountName: "",
        accountNo: "",
        aesKey: "",
        mchChannelId: "",//商户Id
        mchChannelNo: "",//商户号
        mchChannelName: "",//商户名称
        mchKey: "",
        mchPrivateKey: "",//商户RSA私钥
        mchPublicKey: "",//商户RSA公钥
        notifyUrl: "",//入金回调地址
        orgId: "",//所属机构
        orgPublicKey: "",//机构RSA公钥
        receiveAccountBankName: "",
        receiveAccountName: "",
        receiveAccountNo: "",
        allocMchId:this.detail.allocMchId,
        allocMchName:this.detail.allocMchName,
        mchId: this.detail.mchId,
        mchName:this.detail.mchName,
        mchModel:0,
      },
      this.accountBook.mchModel = 0;
      this.addAccountBookShow = true;
    },
    //编辑电子账簿渠道信息
    editAddAccountBook(accountBook) {
      this.accountBook = JSON.parse(JSON.stringify(accountBook));
      this.oldBookKey = accountBook.mchKey;
      console.log(this.oldBookKey, "编辑")
      this.addAccountBookShow = true;
    },
    //删除电子账簿渠道信息
    delAddAccountBook(mchChannelId) {
      this.$confirm("此操作将删除该配置, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delAccountBookChannel(mchChannelId).then((res) => {
          if (res.resultStatus == true) {
            this.getAccountBookDetail(this.$route.query.id);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    //确认添加电子账簿渠道信息
    sureAddAccountBook() {
      let data = JSON.parse(JSON.stringify(this.accountBook));
      if (!data.orgId) {
        this.$message.error("所属机构必选");
        return false;
      }
      if (!data.mchChannelId || !/^[0-9]*$/.test(data.mchChannelId)) {
        this.$message.error("商户号必填，且为正整数");
        return false;
      }
      if(this.AccBooChannelShow == false){
        if (!data.mchPrivateKey) {
            this.$message.error("商户RSA私钥必填");
            return false;
        }
        if (!data.mchPublicKey) {
            this.$message.error("商户RSA公钥必填");
            return false;
        }
        if (!data.orgPublicKey) {
            this.$message.error("机构RSA公钥必填");
            return false;
        }
      }else{
        if (this.AccBooChannel.mchModel==0 && !data.mchPrivateKey) {
            this.$message.error("商户RSA私钥必填");
            return false;
        }
        if (this.AccBooChannel.mchModel==0 && !data.mchPublicKey) {
            this.$message.error("商户RSA公钥必填");
            return false;
        }
        if (this.AccBooChannel.mchModel==0 && !data.orgPublicKey) {
            this.$message.error("机构RSA公钥必填");
            return false;
        } 
      }
      if (data.id) {
        if (data.mchKey === this.oldBookKey) {
          delete data.mchKey;
        }
      }

      this.buttonFlag = true;
      data.id = data.id ? data.id : 0;
      postAccountBookChannelAdd(this.$route.query.id, data).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          this.getAccountBookDetail(this.$route.query.id);
          this.addAccountBookShow = false;
        }
        setTimeout(() => {
          this.buttonFlag = false;
        }, 2000);
      });
    },
    //配置
    configTab(data,type) {
      console.log("this.$route.query.id",this.$route.query.id)
      this.$router.push({
        name: "add_subaccountlist_electron_pz",
        query: {
          data:data,
          id: this.$route.query.id,
        },
      });
    },
    // 跳转页面
    toUrl(name) {
      this.$router.push({
        name: name,
        query: {
          pageType: "edit",
          id: this.$route.query.id,
        },
      });
    },
  }
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
.tab1 {
  min-height: auto !important;
}
.next {
  width: 120px;
  height: 32px;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  padding: 0;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid #dce0e6;
  border-radius: 3px;
  padding: 0;
  font-size: 12px;
  color: #333333;
}

.btn {
  width: 100%;
  margin-top: 50px;
  text-align: center;
  height: 33px;
}

.title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 40px;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}

.overAccount_ul_title i {
  color: #48b8b6;
  padding-right: 3px;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}
.ul_left .item{
  width: 200px !important;
}
.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}
</style>